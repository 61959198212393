import { RoleId } from '@wix/members-domain-ts';

import { origin } from '../../constants/bi';
import { BIEvent, Thunk, ThunkExtra } from '../../types';
import { RootState } from '../root-reducer';
import { getFollowOrUnfollowAction } from '../actions';
import { isMemberInCommunity } from '../selectors';
import { scheduleViewedMemberSync, clearInitialDataCache } from './common';
import { emitBIEventWithPayload } from '../../services/bi-event';
import { joinCommunity } from './role-action/community';

export * from './cover-reposition';
export * from './profile-picture';
export * from './public-profile-preview';
export * from './edit-mode';
export * from './profile-page';
export * from './navigation';
export * from './role-action';
export * from './chat';
export * from './data-sync';

const emitFollowOrUnfollowBIEvent = (state: RootState, extra: ThunkExtra) => {
  const { viewed } = state.users;

  return emitBIEventWithPayload({
    state,
    extra,
    biEvent: BIEvent.FollowOrUnfollow,
    payload: {
      origin,
      member_followed: viewed.uid,
      is_followed: viewed.isSubscribed ? 0 : 1,
    },
  });
};

export const followOrUnfollow: Thunk = () => async (
  dispatch,
  getState,
  extra,
) => {
  const { wixCodeApi, membersService } = extra;
  const state = getState();
  const { current, viewed } = state.users;

  if (!current) {
    wixCodeApi.user.promptLogin({}).catch(() => {});
    return;
  }

  if (!isMemberInCommunity(current)) {
    await joinCommunity(RoleId.JOIN_COMMUNITY)(dispatch, getState, extra);
    return;
  }

  scheduleViewedMemberSync(extra);
  dispatch(getFollowOrUnfollowAction());
  emitFollowOrUnfollowBIEvent(state, extra);
  clearInitialDataCache(state, extra);
  membersService.toggleMemberFollowStatus(viewed.uid, viewed.isSubscribed);
};
