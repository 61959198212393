import { IFrameEvent, Interaction, Thunk } from '../../types';
import { Applications } from '../../services/public-api-store';
import { getSetIsProfilePreviewAction } from '../actions';

export const togglePublicProfilePreview: Thunk = () => async (
  dispatch,
  _,
  { getPublicAPI, dataSyncService, monitoringService },
) => {
  const handlePublicProfileToggle = async () => {
    const membersAreaAPI = await getPublicAPI(Applications.MembersArea);
    await membersAreaAPI?.enterPublicProfilePreviewMode?.();
    dataSyncService.addIFrameEvent(IFrameEvent.EnterPublicProfilePreview);
    dispatch(getSetIsProfilePreviewAction(true));
  };

  await monitoringService.toMonitored(
    Interaction.PublicProfilePreview,
    handlePublicProfileToggle(),
  );
};
