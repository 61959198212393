type ErrorDetails = { [key in string]: string };

export interface WithErrorDetails {
  errorDetails: ErrorDetails;
}

export class HTTPError extends Error implements WithErrorDetails {
  errorDetails: ErrorDetails;

  constructor(message: string, response: Response) {
    super(message);

    const requestId = this.getRequestId(response);
    const requestUrl = this.getRequestUrl(response);
    const responseStatus = this.getResponseStatus(response);

    this.name = 'HTTPError';
    this.errorDetails = {
      ...(requestId && { requestId }),
      ...(requestUrl && { requestUrl }),
      ...(responseStatus && { responseStatus }),
    };
  }

  private getResponseStatus(response: Response) {
    try {
      return response?.status.toString() ?? null;
    } catch {
      return null;
    }
  }

  private getRequestId(response: Response) {
    try {
      return response?.headers?.get?.('x-wix-request-id') ?? null;
    } catch {
      return null;
    }
  }

  private getRequestUrl(response: Response) {
    try {
      return response?.url ?? null;
    } catch {
      return null;
    }
  }
}
